import type { DynamicColDefsFactory, GridState } from '@cmg/data-grid';
import { useDataGridContext } from '@cmg/data-grid';
import { useCallback, useEffect } from 'react';

import {
  allocationSetColDefsFactory,
  demandAtMarketColDef,
  demandLevelColDefsFactory,
  demandLevelColIdMatch,
  extendedDataColDefsFactory,
} from '../columns';
import { DemandGridDataContext } from '../types';

export type Props = Pick<
  DemandGridDataContext,
  'oidcUserCmgEntityKey' | 'allocationSets' | 'demandConfig' | 'investorExtensionSchema'
>;

/**
 * Updates the column definitions based on the allocation sets and demand config.
 *
 * @param allocationSets
 * @param demandConfig
 * @param oidcUserCmgEntityKey
 * @param investorExtensionSchema
 */
export const useDynamicColDefs = ({
  allocationSets,
  demandConfig,
  oidcUserCmgEntityKey,
  investorExtensionSchema,
}: Props) => {
  const { setColDefs, isGridReady, getState, applyGridState } = useDataGridContext();

  const handleSetDynamicColDefs = useCallback(
    (
      colDefFactory: DynamicColDefsFactory,
      onColDefsSet?: (gridState: GridState | null) => void
    ) => {
      if (isGridReady) {
        setColDefs(colDefFactory);
        onColDefsSet?.(getState());
      }
    },
    [getState, isGridReady, setColDefs]
  );

  const reOrderDemandLevelColumns = useCallback(
    (gridState: GridState | null) => {
      const orderedColIds = gridState?.columnOrder?.orderedColIds ?? [];
      const [demandLevelColIds, restColIds] = orderedColIds.reduce<[string[], string[]]>(
        (acc, colId) => {
          const [demandLevelColIds, restColIds] = acc;

          if (colId.match(demandLevelColIdMatch)) {
            return [[...demandLevelColIds, colId], restColIds];
          }

          return [demandLevelColIds, [...restColIds, colId]];
        },
        [[], []]
      );

      const insertAtIndex = restColIds.indexOf(demandAtMarketColDef.field!) + 1;

      applyGridState({
        ...gridState,
        columnOrder: {
          orderedColIds: [
            ...restColIds.slice(0, insertAtIndex),
            ...demandLevelColIds,
            ...restColIds.slice(insertAtIndex),
          ],
        },
      });
    },
    [applyGridState]
  );

  useEffect(() => {
    handleSetDynamicColDefs(allocationSetColDefsFactory(allocationSets, oidcUserCmgEntityKey));
  }, [allocationSets, handleSetDynamicColDefs, oidcUserCmgEntityKey]);

  useEffect(() => {
    handleSetDynamicColDefs(demandLevelColDefsFactory(demandConfig), reOrderDemandLevelColumns);
  }, [demandConfig, handleSetDynamicColDefs, reOrderDemandLevelColumns]);

  useEffect(() => {
    handleSetDynamicColDefs(extendedDataColDefsFactory(investorExtensionSchema));
  }, [handleSetDynamicColDefs, investorExtensionSchema]);
};
