import { useAuth } from '@cmg/auth';
import type { UUID } from '@cmg/common';
import { useMemo } from 'react';

import type { GetDemandLevelConfigFromOfferingProps } from '../DemandGrid.model';
import { getDemandLevelConfigFromOffering } from '../DemandGrid.model';
import { useStoredDemandConfig } from './useStoredDemandConfig';

export type Props = Readonly<{
  offeringId: UUID;
  filingValues: GetDemandLevelConfigFromOfferingProps | undefined;
}>;

/**
 * Return initial demand config values by either:
 * - Reading last used values from local storage
 * - Computing values based on Offering Setup data
 *
 * @param offeringId
 * @param filingValues
 */
export const useGetInitialDemandConfig = ({ offeringId, filingValues }: Props) => {
  const { oidcUserId } = useAuth();

  const { storedDemandConfig } = useStoredDemandConfig({
    offeringId,
    oidcUserId: oidcUserId ?? '',
  });

  return useMemo(() => {
    return storedDemandConfig ?? getDemandLevelConfigFromOffering(filingValues);
  }, [storedDemandConfig, filingValues]);
};
