import { numericUtil } from '@cmg/common';
import type {
  DataTypeDefinition,
  GetRowIdFunc,
  GridRowSelectionState,
  RowHeightParams,
  StatusPanelDef,
} from '@cmg/data-grid';
import isNil from 'lodash/isNil';
import merge from 'lodash/merge';
import round from 'lodash/round';

import type { SyndicateInstitutionalGridRowFilterInput } from '../../../../graphql';
import { IndicationStatus, InstitutionalIndicationOrderType } from '../../../../graphql';
import type { OrderBook_InstitutionalDemand_PublishedOfferingPartsFragment } from '../graphql/__generated__';
import { managerProfileToFirmInfo } from '../utils';
import { SummaryMetricsStatusPanel } from './status-bar/SummaryMetricsStatusPanel';
import type { DemandConfigValues, DemandGridDataContext, DemandGridRowData } from './types';

export const UNASSIGN_BND_AGENT_VALUE = 'unassign';
export const MAX_DEMAND_COlS = 20;
export const INCREMENT_MIN = 0.01;
export const INCREMENT_MAX = 1_000;

export type GetDemandLevelConfigFromOfferingProps = Pick<
  OrderBook_InstitutionalDemand_PublishedOfferingPartsFragment,
  | 'derivedOfferPrice'
  | 'initialFilingPriceRangeLow'
  | 'initialFilingPriceRangeHigh'
  | 'latestFilingPriceRangeLow'
  | 'latestFilingPriceRangeHigh'
>;

export const defaultDemandConfigValues: DemandConfigValues = {
  min: 0.1,
  max: 1,
  increment: 1,
};

/**
 * Empty group key constant
 */
const EMPTY_GROUP_KEY = 'empty-group';

export const isEmptyGroupKey = (groupKey: string) => !groupKey || groupKey === EMPTY_GROUP_KEY;

/**
 * Status Panels definition
 */
export const statusPanels: StatusPanelDef[] = [
  { key: 'summaryMetrics', align: 'left', statusPanel: SummaryMetricsStatusPanel },
];

/**
 * Data type definitions
 *
 * TODO: Primitive data type definitions should be moved to the data-grid package eventually when we extract formatters from @cmg/common pkg.
 */
export const dataTypeDefinitions = {
  number: {
    baseDataType: 'number',
    extendsDataType: 'number',
    valueParser: params => {
      const parsedValue = numericUtil.parseNumber(params.newValue);
      const oldValue = 'oldValue' in params ? params.oldValue : undefined;

      return parsedValue ?? oldValue;
    },
  },
  firmInfo: {
    baseDataType: 'object',
    extendsDataType: 'object',
    valueParser: params => {
      const { newValue, context } = params;
      const { syndicateManagers } = context as DemandGridDataContext;
      const oldValue = 'oldValue' in params ? params.oldValue : undefined;

      if (!newValue || newValue === 'Unassigned') {
        return null;
      }

      const nextBndAgent = Object.values(syndicateManagers).find(
        manager => manager.firmNameAbbreviation === newValue || manager.firmName === newValue
      );

      return nextBndAgent ? managerProfileToFirmInfo(nextBndAgent) : oldValue;
    },
  },
} satisfies Record<string, DataTypeDefinition<DemandGridRowData>>;

/**
 * Get the maximum demand increment for the given price range.
 *
 * @param min
 * @param max
 * @param suggestedIncrement
 */
export const getMaxDemandIncrementForRange = (
  min: number,
  max: number,
  suggestedIncrement: number
) => {
  if (max < min) {
    throw new Error('Max value must be greater than min value');
  }

  if (suggestedIncrement < INCREMENT_MIN || suggestedIncrement > INCREMENT_MAX) {
    throw new Error('Increment value must be between 0.01 and 1000');
  }

  const diff = max - min;
  /**
   * Compute how low the increment would need to be to produce the maximum allowed number of columns MAX_DEMAND_COLS.
   * If it already exceeds the INCREMENT_MAX constraint, use INCREMENT_MAX instead.
   */
  const maxComputedIncrement = round(Math.min(diff / MAX_DEMAND_COlS, INCREMENT_MAX), 2);

  /**
   * Use increment that produces fewer generated columns.
   */
  return Math.max(maxComputedIncrement, suggestedIncrement);
};

/**
 * Computes demand level config values based on Offering Setup data.
 * Return artificial default config if Offering Setup data are not defined.
 * @param props
 */
export const getDemandLevelConfigFromOffering = (
  props: GetDemandLevelConfigFromOfferingProps | undefined
) => {
  if (!props) {
    return null;
  }

  const {
    latestFilingPriceRangeHigh,
    latestFilingPriceRangeLow,
    initialFilingPriceRangeLow,
    initialFilingPriceRangeHigh,
    derivedOfferPrice,
  } = props;

  const min =
    latestFilingPriceRangeLow ||
    initialFilingPriceRangeLow ||
    derivedOfferPrice ||
    defaultDemandConfigValues.min;

  const max =
    latestFilingPriceRangeHigh ||
    initialFilingPriceRangeHigh ||
    derivedOfferPrice ||
    defaultDemandConfigValues.max;

  const increment = getMaxDemandIncrementForRange(min, max, defaultDemandConfigValues.increment);

  return { min, max, increment, referencePrice: derivedOfferPrice };
};
/**
 * Get the filter input for the bulk actions by combining:
 * - active grid filters defined by the user
 * - manually selected/unselected rows.
 * - excluding non-active and pass indications
 *
 * @param rowSelectionState - The row selection state
 * @param gridFilterInput - The grid filter input currently applied
 */
export const getBulkActionsFilterInput = (
  rowSelectionState: GridRowSelectionState,
  gridFilterInput: Record<string, unknown> | undefined
): SyndicateInstitutionalGridRowFilterInput => {
  const rowSelectionFilter: SyndicateInstitutionalGridRowFilterInput = {
    id: rowSelectionState.selectAll
      ? { nin: rowSelectionState.unselectedRowIdsFlat }
      : { in: rowSelectionState.selectedRowIdsFlat },
    type: { neq: InstitutionalIndicationOrderType.Pass },
  };

  /**
   * Bulk actions can only be applied to Active indications
   */
  return {
    ...merge({}, gridFilterInput, rowSelectionFilter),
    status: { eq: IndicationStatus.Active },
  };
};

/**
 * Decides whenever the row can be selected to perform bulk actions or not.
 *
 * @param data - Row data
 */
export const isRowSelectable = (data: DemandGridRowData | undefined) => {
  if (!data || data.__typename !== 'SyndicateInstitutionalGridRow') {
    return false;
  }

  return (
    data?.status === IndicationStatus.Active && data?.type !== InstitutionalIndicationOrderType.Pass
  );
};

export const isServerSideGroup = (data: DemandGridRowData) => {
  return (
    data?.__typename === 'Group' ||
    (data?.__typename === 'SyndicateInstitutionalGridRow' && data.hasDuplicates)
  );
};

export const getServerSideGroupKey = (data: DemandGridRowData | null | undefined) => {
  if (data?.__typename === 'SyndicateInstitutionalGridRow') {
    return data.id;
  }

  if (data?.__typename === 'Group') {
    return data.groupKey ?? EMPTY_GROUP_KEY;
  }

  return '';
};

export const getRowId: GetRowIdFunc<DemandGridRowData> = params => {
  if (isNil(params.data)) {
    return '';
  }

  if (params.data.__typename === 'Group') {
    return params.data.groupKey ?? EMPTY_GROUP_KEY;
  }

  if (params.data.__typename !== 'SyndicateInstitutionalGridRow') {
    return 'total-row';
  }

  const parentKeysJoined = (params.parentKeys || []).join('-');
  return parentKeysJoined + params.data.id;
};

export const getRowHeight = (params: RowHeightParams<DemandGridRowData, DemandGridDataContext>) => {
  return params.node.rowPinned ? 30 : undefined;
};

export const getChildCount = (data: DemandGridRowData | null) => {
  return data?.__typename === 'Group' ? data.count : NaN;
};
