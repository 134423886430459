import { useStoredPreference } from '@cmg/auth';

import { DemandConfigValues } from '../types';

export type Props = Readonly<{
  offeringId: string;
  oidcUserId: string;
}>;

export const useStoredDemandConfig = ({ offeringId, oidcUserId }: Props) => {
  const [storedDemandConfig, setStoredDemandConfig] =
    useStoredPreference<DemandConfigValues | null>({
      key: `demand_config_${offeringId}_${oidcUserId}`,
      storageType: 'Local',
      initialValue: null,
    });
  return { storedDemandConfig, setStoredDemandConfig };
};
