import { useAuth } from '@cmg/auth';
import { useCallback, useEffect, useState } from 'react';

import type {
  OrderBook_InstitutionalDemand_AllocationSetPartsFragment,
  OrderBook_InstitutionalDemand_InvestorExtensionSchemaPartsFragment,
  OrderBook_InstitutionalDemand_ManagerProfilePartsFragment,
} from '../../graphql/__generated__';
import type { DemandConfigValues, DemandGridDataContext } from '../types';

export type Props = Readonly<{
  offeringId: string;
  allocationSets: readonly OrderBook_InstitutionalDemand_AllocationSetPartsFragment[] | undefined;
  initialDemandConfig: DemandConfigValues | null;
  pricingCurrencyCode: string;
  syndicateManagers:
    | readonly OrderBook_InstitutionalDemand_ManagerProfilePartsFragment[]
    | undefined;
  investorExtensionSchema:
    | readonly OrderBook_InstitutionalDemand_InvestorExtensionSchemaPartsFragment[]
    | undefined;
}>;

export type UpdateDemandGridDataContextFn = (nextContext: Partial<DemandGridDataContext>) => void;

/**
 * !!! This is not React Context !!!
 * See https://www.ag-grid.com/react-data-grid/context/
 *
 * Creates the demand grid data context object which is later used when rendering the individual columns of the grid.
 * It contains additional metadata viable for each row in the grid.
 */
export const useDemandGridDataContextObject = (props: Props) => {
  const { oidcUserCmgEntityKey } = useAuth();

  const [context, setContext] = useState<DemandGridDataContext>({
    investorExtensionSchema: props.investorExtensionSchema ?? null,
    oidcUserCmgEntityKey,
    allocationSets: { drafts: {} },
    pricingCurrencyCode: props.pricingCurrencyCode,
    totalSummaryMetrics: null,
    selectedRowsSummaryMetrics: null,
    totalRow: null,
    demandConfig: props.initialDemandConfig,
    syndicateManagers: {},
    explicitChanges: {},
    offeringId: props.offeringId,
    groupKeys: [],
  });

  const updateContext = useCallback((nextContext: Partial<DemandGridDataContext>) => {
    setContext(prevContext => ({
      ...prevContext,
      ...nextContext,
    }));
  }, []);

  useEffect(() => {
    setContext(prevContext => ({
      ...prevContext,
      pricingCurrencyCode: props.pricingCurrencyCode,
    }));
  }, [props.pricingCurrencyCode]);

  useEffect(() => {
    setContext(prevContext => ({
      ...prevContext,
      investorExtensionSchema: props.investorExtensionSchema ?? null,
    }));
  }, [props.investorExtensionSchema]);

  useEffect(() => {
    setContext(prevContext => ({
      ...prevContext,
      demandConfig: props.initialDemandConfig,
    }));
  }, [props.initialDemandConfig]);

  useEffect(() => {
    const nextManagers = (props.syndicateManagers ?? []).reduce<
      DemandGridDataContext['syndicateManagers']
    >((acc, curr) => {
      return { ...acc, [curr.cmgEntityKey]: curr };
    }, {});

    setContext(prevContext => ({
      ...prevContext,
      syndicateManagers: nextManagers,
    }));
  }, [props.syndicateManagers]);

  useEffect(() => {
    if (!props.allocationSets) {
      return;
    }

    const nextAllocationSets = props.allocationSets.reduce(
      (acc, curr) => {
        if (curr.isFinal) {
          return { ...acc, final: curr };
        }

        return { ...acc, drafts: { ...acc.drafts, [curr.id]: curr } };
      },
      { drafts: {} }
    ) ?? { drafts: {} };

    setContext(prevContext => ({
      ...prevContext,
      allocationSets: nextAllocationSets,
    }));
  }, [props.allocationSets]);

  return { demandGridContext: context, updateContext };
};
