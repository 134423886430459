import type { ApolloError } from '@apollo/client';
import type { UUID } from '@cmg/common';
import type { GridRowSelectionState } from '@cmg/data-grid';
import { DataGridContextProvider } from '@cmg/data-grid';
import { Alert, Stack } from '@cmg/design-system';
import React, { useCallback, useState } from 'react';
import type { RouteComponentProps } from 'react-router';
import { useDebouncedCallback } from 'use-debounce';

import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle/useDocumentTitle';
import useCheckInstitutionalPassiveOrderBookAccess from '../../../common/util/check-access/useCheckInstitutionalPassiveOrderBookAccess';
import routeFactory from '../../../common/util/routeFactory';
import { OfferingStatus, OfferingType, OrderBookState } from '../../../graphql';
import { OrderBookPageLayout } from '../components/order-book-page-layout/OrderBookPageLayout';
import { UpdateOrderBookStatusModal } from '../components/update-orderbook-status-modal/UpdateOrderBookStatusModal';
import { useUpdateOrderBookStatusForSales } from '../hooks/useUpdateOrderBookStatusForSales';
import { useExportInstitutionalDemandProspectusEmails } from '../institutional-demand/components/institutional-demand-grid/components/institutional-demand-orders-and-allocations-export-button/hooks/useExportInstitutionalDemandProspectusEmails';
import { useExportInstitutionalDemandRevisionHistory } from '../institutional-demand/components/institutional-demand-grid/components/institutional-demand-orders-and-allocations-export-button/hooks/useExportInstitutionalDemandRevisionHistory';
import { ProspectusOnSendValidationAlert } from '../institutional-demand/components/institutional-demand-grid/components/prospectus-alert/ProspectusOnSendValidationAlert';
import { BulkActions } from './bulk-actions/BulkActions';
import { BulkAssignBndAgentDialog } from './bulk-actions/dialogs/BulkAssignBndAgentDialog';
import { BulkEnterAllocationsDialog } from './bulk-actions/dialogs/BulkEnterAllocationsDialog';
import { MarkAsDuplicateDialog } from './bulk-actions/dialogs/MarkAsDuplicateDialog';
import { DemandGrid } from './demand-grid/DemandGrid';
import { useDemandGridDataContextObject } from './demand-grid/hooks/useDemandGridDataContextObject';
import { useGetInitialDemandConfig } from './demand-grid/hooks/useGetInitialDemandConfig';
import { ReleaseFinalAllocationComplianceDialog } from './dialogs/ReleaseFinalAllocationComplianceDialog';
import { ReleaseFinalAllocationDialog } from './dialogs/ReleaseFinalAllocationDialog';
import { UpdateReleasedFinalAllocationDialog } from './dialogs/UpdateReleasedFinalAllocationDialog';
import {
  useOrderBook_InstitutionalDemand_SummariesLazyQuery,
  useOrderBook_InstitutionalDemandRouteQuery,
} from './graphql/__generated__';
import { useAssignBndAgent } from './hooks/useAssingBndAgent';
import { useBulkAssignBndAgentDialogState } from './hooks/useBulkAssignBndAgentDialogState';
import { useBulkEnterAllocationsDialogState } from './hooks/useBulkEnterAllocationsDialogState';
import { useBulkSendProspectusState } from './hooks/useBulkSendProspectusState';
import { useComplianceDialogState } from './hooks/useComplianceDialogState';
import { useEnterAllocation } from './hooks/useEnterAllocation';
import { useExportInstitutionalDemand } from './hooks/useExportInstitutionalDemand';
import { useMarkAsDuplicateDialogState } from './hooks/useMarkAsDuplicateDialogState';
import { useReleaseFinalAllocationsDialogState } from './hooks/useReleaseFinalAllocationsDialogState';
import { useSubNavActions } from './hooks/useSubNavActions';
import { useUpdateOrderBookStatusDialogState } from './hooks/useUpdateOrderBookStatusDialogState';
import { useUpdateReleasedFinalAllocationDialogState } from './hooks/useUpdateReleasedFinalAllocationDialogState';

export type Props = RouteComponentProps & {
  offeringId: UUID;
  offeringStatus: OfferingStatus;
  offeringType: OfferingType;
  orderBookState?: OrderBookState;
  issuerName?: string;
  error?: ApolloError;
};

/**
 * Route for the Order Book Institutional Demand Page v2
 */
const InstitutionalDemandRouteComponent: React.FC<Props> = ({
  issuerName,
  offeringId,
  offeringStatus,
  offeringType,
  orderBookState,
  error,
}) => {
  useDocumentTitle(
    routeFactory.orderBookInstitutionalDemand.getDocumentTitle({
      issuerName,
    })
  );

  const { canManage: canManagePassiveOrderBook } = useCheckInstitutionalPassiveOrderBookAccess({
    offeringId,
  });

  const [rowSelectionState, setRowSelectionState] = useState<GridRowSelectionState>({
    selectAll: false,
    selectedRowIds: [],
    selectedRowIdsFlat: [],
  });

  const routeQuery = useOrderBook_InstitutionalDemandRouteQuery({ variables: { offeringId } });

  const initialDemandConfig = useGetInitialDemandConfig({
    offeringId,
    filingValues: routeQuery.data?.publishedOffering,
  });

  const { demandGridContext, updateContext } = useDemandGridDataContextObject({
    initialDemandConfig,
    investorExtensionSchema: routeQuery.data?.investorExtensionSchema,
    allocationSets: routeQuery.data?.allocationSets,
    pricingCurrencyCode: routeQuery.data?.publishedOffering?.pricingCurrencyCode ?? 'USD',
    syndicateManagers: routeQuery.data?.publishedOffering?.syndicate.managers,
    offeringId,
  });

  const finalAllocationSetId = demandGridContext.allocationSets.final?.id;

  /**
   * Summary metrics of selected rows
   */
  const [loadGridSummaries, gridSummariesQuery] =
    useOrderBook_InstitutionalDemand_SummariesLazyQuery({
      fetchPolicy: 'cache-and-network',
    });

  const loadMetricsForSelectedRows = useDebouncedCallback(
    async (rowSelectionState: GridRowSelectionState) => {
      if (!demandGridContext.demandConfig) {
        return;
      }

      if (rowSelectionState.selectAll || !rowSelectionState.selectedRowIds.length) {
        updateContext({ selectedRowsSummaryMetrics: null });
        return;
      }

      const { data } = await loadGridSummaries({
        variables: {
          offeringId,
          where: { id: { in: rowSelectionState.selectedRowIdsFlat } },
          ...demandGridContext.demandConfig,
        },
      });

      updateContext({
        selectedRowsSummaryMetrics: data?.syndicateInstitutionalGrid?.summaries.summaryMetrics,
      });
    },
    750
  );

  /**
   * Individual row actions
   */
  const handleRowSelectionChanged = useCallback(
    async (newRowSelectionState: GridRowSelectionState) => {
      setRowSelectionState(newRowSelectionState);
      await loadMetricsForSelectedRows(newRowSelectionState);
    },
    [loadMetricsForSelectedRows]
  );

  const handleAssignBndAgent = useAssignBndAgent({ offeringId });

  const handleEnterAllocation = useEnterAllocation({
    offeringId,
    finalAllocationSetId: demandGridContext.allocationSets.final?.id,
  });

  const handleDiscardExplicitChanges = useCallback(() => {
    updateContext({ explicitChanges: {} });
  }, [updateContext]);

  const {
    loading: isUpdatingOrderBookSalesStateLoading,
    orderBookSalesState,
    handleOnUpdateOrderBookSalesState,
  } = useUpdateOrderBookStatusForSales({ offeringId });

  /**
   * Sub Nav - Action buttons
   */
  const {
    onSave: handleUpdateOrderBookStatus,
    onOpen: handleUpdateOrderBookStatusDialogOpen,
    onClose: handleUpdateOrderBookStatusDialogClose,
    isOpen: isUpdateOrderBookStatusDialogOpen,
    loading: updateOrderBookStatusLoading,
    error: updateOrderBookStatusError,
  } = useUpdateOrderBookStatusDialogState({ offeringId, orderBookState });

  const {
    onOpen: handleReleaseFinalAllocationComplianceDialogOpen,
    onClose: handleReleaseFinalAllocationComplianceDialogClose,
    onContinue: handleReleaseFinalAllocationComplianceDialogContinue,
    onShowInvestors: handleReleaseFinalAllocationComplianceDialogShowInvestors,
    isOpen: isReleaseFinalAllocationComplianceDialogOpen,
  } = useComplianceDialogState();

  const {
    onOpen: handleReleaseFinalAllocationsDialogOpen,
    onClose: handleReleaseFinalAllocationsDialogClose,
    onSave: handleReleaseFinalAllocations,
    isOpen: isReleaseFinalAllocationsDialogOpen,
    loading: releaseFinalAllocationsLoading,
    error: releaseFinalAllocationsError,
  } = useReleaseFinalAllocationsDialogState({ offeringId });

  const {
    onOpen: handleUpdateReleasedFinalAllocationDialogOpen,
    onClose: handleUpdateReleasedFinalAllocationDialogClose,
    onSave: handleUpdateReleasedFinalAllocation,
    isOpen: isUpdateReleasedFinalAllocationDialogOpen,
    loading: updateReleasedFinalAllocationLoading,
    error: updateReleasedFinalAllocationError,
  } = useUpdateReleasedFinalAllocationDialogState({
    offeringId,
    finalAllocationSetId,
    onDiscard: handleDiscardExplicitChanges,
  });

  const [handleExportInstitutionalDemand, { loading: isExportInstitutionalDemandLoading }] =
    useExportInstitutionalDemand({
      offeringId,
      demandConfig: demandGridContext.demandConfig,
    });

  const { handleExport: handleExportRevisionHistory, loading: revisionHistoryLoading } =
    useExportInstitutionalDemandRevisionHistory({ offeringId });

  const { handleExport: handleExportProspectusEmails, loading: prospectusEmailsLoading } =
    useExportInstitutionalDemandProspectusEmails({ offeringId });

  /**
   * Toolbar - Bulk Actions
   */
  const {
    onSave: handleBulkEnterAllocationsSave,
    onOpen: handleBulkEnterAllocationsDialogOpen,
    onClose: handleBulkEnterAllocationsDialogClose,
    isOpen: isBulkEnterAllocationsDialogOpen,
    error: bulkEnterAllocationsError,
    loading: bulkEnterAllocationsLoading,
  } = useBulkEnterAllocationsDialogState({
    offeringId,
    rowSelectionState,
    demandConfig: demandGridContext.demandConfig,
    finalAllocationSet: demandGridContext.allocationSets.final,
    onShowComplianceBeforeSave: handleReleaseFinalAllocationComplianceDialogOpen,
  });

  const {
    onSave: handleBulkAssignBndAgentSave,
    onOpen: handleBulkAssignBndAgentDialogOpen,
    onClose: handleBulkAssignBndAgentDialogClose,
    isOpen: isBulkAssignBndAgentDialogOpen,
    error: bulkAssignBndAgentError,
    loading: bulkAssignBndAgentLoading,
  } = useBulkAssignBndAgentDialogState({
    offeringId,
    demandConfig: demandGridContext.demandConfig,
    rowSelectionState,
  });

  const {
    onSave: handleMarkAsDuplicateSave,
    onOpen: handleMarkAsDuplicateDialogOpen,
    onClose: handleMarkAsDuplicateDialogClose,
    isOpen: isMarkAsDuplicateDialogOpen,
    context: markAsDuplicateDialogContext,
    error: markAsDuplicateError,
    loading: markAsDuplicateLoading,
  } = useMarkAsDuplicateDialogState({ offeringId, rowSelectionState });

  const {
    onSave: handleBulkSendProspectusSave,
    loading: bulkSendProspectusLoading,
    error: bulkSendProspectusError,
    investors: bulkSendProspectusInvestors,
    validation: bulkSendProspectusValidation,
  } = useBulkSendProspectusState({
    offeringId,
    rowSelectionState,
    demandConfig: demandGridContext.demandConfig,
  });

  const subNavActions = useSubNavActions({
    offeringId,
    offeringStatus,
    orderBookState,
    finalAllocationSet: demandGridContext.allocationSets.final,
    pricingCurrencyCode: demandGridContext.pricingCurrencyCode,
    demandConfig: demandGridContext.demandConfig,
    orderBookSalesState,
    saveAllocationChanges: {
      onClick: () => {
        handleReleaseFinalAllocationComplianceDialogOpen({
          onContinue: handleUpdateReleasedFinalAllocationDialogOpen,
        });
      },
      onDiscard: handleDiscardExplicitChanges,
      loading: updateReleasedFinalAllocationLoading,
      changes: demandGridContext.explicitChanges,
    },
    updateOrderBookStatus: {
      loading: updateOrderBookStatusLoading,
      onClick: handleUpdateOrderBookStatusDialogOpen,
    },
    updateOrderBookSalesState: {
      loading: isUpdatingOrderBookSalesStateLoading,
      onClick: handleOnUpdateOrderBookSalesState,
    },
    exportInstitutionalDemand: {
      loading: isExportInstitutionalDemandLoading,
      onClick: handleExportInstitutionalDemand,
    },
    exportProspectusEmails: {
      loading: prospectusEmailsLoading,
      onClick: handleExportProspectusEmails,
    },
    exportRevisionHistory: {
      loading: revisionHistoryLoading,
      onClick: handleExportRevisionHistory,
    },
    releaseFinalAllocations: {
      loading: releaseFinalAllocationsLoading,
      onClick: () => {
        handleReleaseFinalAllocationComplianceDialogOpen({
          onContinue: handleReleaseFinalAllocationsDialogOpen,
        });
      },
    },
  });

  const gqlError =
    error ||
    routeQuery.error ||
    gridSummariesQuery.error ||
    bulkEnterAllocationsError ||
    bulkAssignBndAgentError ||
    markAsDuplicateError ||
    bulkSendProspectusError ||
    updateOrderBookStatusError ||
    releaseFinalAllocationsError ||
    updateReleasedFinalAllocationError;

  const selectableRowCount =
    demandGridContext.totalSummaryMetrics?.totalActiveNonPassIndicationCount;

  return (
    <OrderBookPageLayout subNavigationProps={{ offeringId, actions: subNavActions }}>
      <Stack direction="column" flexGrow="1">
        {canManagePassiveOrderBook && (
          <Alert severity="info">
            This collection of indications is private and will not be shared with active bookrunners
            or investors.
          </Alert>
        )}
        {gqlError && <Alert severity="error">{gqlError.message}</Alert>}
        <ProspectusOnSendValidationAlert
          investors={bulkSendProspectusInvestors}
          prospectusOnSendValidation={bulkSendProspectusValidation}
        />
        <BulkActions
          offeringId={offeringId}
          rowSelectionState={rowSelectionState}
          totalSelectableRowCount={selectableRowCount}
          isProspectusDocumentUploaded={
            !!routeQuery.data?.publishedOffering.prospectusDocuments.length
          }
          onEnterAllocations={handleBulkEnterAllocationsDialogOpen}
          isSavingAllocations={bulkEnterAllocationsLoading}
          onAssignBndAgent={handleBulkAssignBndAgentDialogOpen}
          isSavingBndAgent={bulkAssignBndAgentLoading}
          onMarkAsDuplicate={handleMarkAsDuplicateDialogOpen}
          isSavingMarkAsDuplicate={markAsDuplicateLoading}
          onSendProspectus={handleBulkSendProspectusSave}
          isSavingSendProspectus={bulkSendProspectusLoading}
        />
        <DemandGrid
          offeringStatus={offeringStatus}
          offeringType={offeringType}
          demandGridContext={demandGridContext}
          updateContext={updateContext}
          onBndAgentChanged={handleAssignBndAgent}
          onAllocationChanged={handleEnterAllocation}
          onRowSelectionChanged={handleRowSelectionChanged}
          refetchRouteQuery={routeQuery.refetch}
        />
        <BulkEnterAllocationsDialog
          allocationSets={demandGridContext.allocationSets}
          isOpen={isBulkEnterAllocationsDialogOpen}
          onSave={handleBulkEnterAllocationsSave}
          onClose={handleBulkEnterAllocationsDialogClose}
        />
        <BulkAssignBndAgentDialog
          managers={Object.values(demandGridContext.syndicateManagers)}
          isOpen={isBulkAssignBndAgentDialogOpen}
          onSave={handleBulkAssignBndAgentSave}
          onClose={handleBulkAssignBndAgentDialogClose}
        />
        <MarkAsDuplicateDialog
          indications={markAsDuplicateDialogContext ?? []}
          pricingCurrencyCode={demandGridContext.pricingCurrencyCode}
          finalAllocationSetId={demandGridContext.allocationSets.final?.id}
          isOpen={isMarkAsDuplicateDialogOpen}
          onSave={handleMarkAsDuplicateSave}
          onClose={handleMarkAsDuplicateDialogClose}
        />
        <UpdateOrderBookStatusModal
          open={isUpdateOrderBookStatusDialogOpen}
          onClose={handleUpdateOrderBookStatusDialogClose}
          onUpdateOrderBookStatus={handleUpdateOrderBookStatus}
          orderBookState={orderBookState}
        />
        <ReleaseFinalAllocationComplianceDialog
          offeringId={offeringId}
          isOpen={isReleaseFinalAllocationComplianceDialogOpen}
          onClose={handleReleaseFinalAllocationComplianceDialogClose}
          onContinue={handleReleaseFinalAllocationComplianceDialogContinue}
          onShowInvestors={handleReleaseFinalAllocationComplianceDialogShowInvestors}
        />
        <ReleaseFinalAllocationDialog
          offeringName={issuerName}
          show={isReleaseFinalAllocationsDialogOpen}
          handleHide={handleReleaseFinalAllocationsDialogClose}
          handleDestroy={() => {}}
          onSubmit={handleReleaseFinalAllocations}
        />
        <UpdateReleasedFinalAllocationDialog
          offeringId={offeringId}
          finalAllocationSetId={demandGridContext.allocationSets.final?.id}
          explicitChanges={demandGridContext.explicitChanges}
          isOpen={isUpdateReleasedFinalAllocationDialogOpen}
          onClose={handleUpdateReleasedFinalAllocationDialogClose}
          onSaveChanges={handleUpdateReleasedFinalAllocation}
          onDiscardChanges={handleDiscardExplicitChanges}
        />
      </Stack>
    </OrderBookPageLayout>
  );
};

export const InstitutionalDemandRoute: React.FC<Props> = props => {
  return (
    <DataGridContextProvider>
      <InstitutionalDemandRouteComponent {...props} />
    </DataGridContextProvider>
  );
};
